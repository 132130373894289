<template>
<!--  <div style="margin: 4.26667vw;">-->
<!--    <van-button round block type="primary" @click="onAdd">新增</van-button>-->
<!--  </div>-->
  <GridList title="当前可用车辆信息" portName="tms.TmsVehicle.vapp.getMyDispatchCar"
            :request-data="{'distinctVehicle': 'vapp'}"
            ref="grid"
            :dataList="dataList" style="margin: 4.26667vw;">

    <ListCard
        v-for="item in dataList"
        :key="item.id"

        :id="item.id"
    >
      <van-cell :key="item.id" :title="item['carCodeHead']" :value="item['vehicleModel.name']"
                />
<!--      is-link @click="onEdit(item.id)"-->
    </ListCard>

  </GridList>
</template>

<script>

import GridList from "../../components/GridList";
import ListCard from "../../components/ListCard";

export default {
  name: "VisitorCarList",
  components: {ListCard, GridList},
  data() {
    return {
      visitorID: '',
      dataList: [],
    };
  },
  methods: {
    onDel: function (id) {
      this.$dialog.confirm({
        title: '',
        message: '是否确定删除该记录？',
      })
          .then(() => {
            var option = {
              portName: 'osp.visitor.VisitorCar.delVisitorCar',
              data: {ids: [id]},
              needLoading: false,
              successCallback: () => {
                this.$notify({type: 'success', message: '删除成功'})
                this.$refs.grid.onRefresh();
              }
            }
            this.$sapi.callPort(option);
          })
          .catch(() => {

          });
    },
    onEdit: function (id) {
      this.$router.push({name: 'visitorCar', params: {id: id}})
    },
    onAdd: function () {
      this.$router.push({name: 'visitorCar'})
    },

  },
  created: function () {
    var visitor = this.$sapi.storage.getItemJson('visitor');
    this.visitorID = visitor.id;
  }
}
</script>

<style scoped>

</style>